var Papa = require("papaparse");

export const slugify = function(str) {
  // https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;'
  const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return str.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(p, c => b.charAt(a.indexOf(c))) 
    .replace(/&/g, '-and-') 
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

export const cookie = {
  set: function(name, options) {
    const domain = options.domain ? `domain=${options.domain};` : '';
    
    document.cookie = name + "=" + encodeURI(options.value) + ";path=/;expires=" + options.expires + domain;
  },
  get: function(name) {
    var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
    return !!pair ? decodeURI(pair[1]) : null;
  },
  expires: function(days) {
    var d = new Date();
        d.setDate(d.getDate() + days);
    return d.toUTCString();
  }
}

export const loadState = function(form = null) {
  const state_name = form ? form : 'state';
  try {
    var serializedState = localStorage.getItem(state_name);
    if (serializedState == null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}
  
export const saveState = function (form = null, state) {
  const state_name = form ? form : 'state';
  try {
    var serializedState = JSON.stringify(state);
    localStorage.setItem(state_name, serializedState);
  } catch (err) {
    //
  }
}

export const collectQueries = function (formSelector) {
  const queryFields = [
                      'utm_medium',
                      'utm_source',
                      'utm_campaign',
                      'utm_term',
                      'utm_content',
                      'utm_adgroup',
                      'pcid',
                      'pcn',
                      'pcrid',
                      'pkwid',
                      'pscid'
                    ];
  let utms = loadState(formSelector) ? loadState(formSelector) : {};
  
  for ( let i = 0; i < queryFields.length; i++) {
    const name = queryFields[i]
    const value = getParameterByName(name)

    if ( (utms[name] && (value && utms[name] !== value) ) || (!utms[name] && value) ) {
      utms[name] = value
    }
    
    if ( utms[name] && $(formSelector).length ) {
      setHiddenInputs(name, utms[name])
    }
  }

  saveState(formSelector, utms) 

  function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  function setHiddenInputs(input, source) {
    const $input =  $(formSelector).find(`input[name="${input}"]`);
    
    if (source && !$input.length) $(formSelector).append(`<input type="hidden" name="${input}" value="${source}" />`)
    if (source && $input.length) $(formSelector).find(`input[name="${input}"]`).val(source);

  }
}

export const CSVtoJSON = function (csvURL, complete) {
  Papa.parse(csvURL, {
    skipEmptyLines: true,
    header: true,
    download: true,
    complete
  });
}

export const inViewport = elem => {
  const bounding = elem.getBoundingClientRect();

  return (
    bounding.bottom >= window.innerHeight / 2 &&
    bounding.top <= window.innerHeight / 2
  );
};

export const equalizeHeight = function (element) {
  let height = 0;
      
  element.each(function() {
    $(this).css('min-height', '0');
  });
  
  element.each(function() {
    if ($(this).outerHeight() > height) {
      height = $(this).outerHeight();
    }
  });
  
  element.each(function() {
    $(this).css('min-height', `${height}px`);
  });
}